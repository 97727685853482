import { action, makeObservable, observable } from 'mobx';

import { Guest, Guest as GuestModel } from '@data/models';

export default class Guests {
  @observable additional: GuestModel[] = [];
  @observable main: Guest = new Guest();

  constructor() {
    makeObservable(this);
  }

  @action.bound add(guest: GuestModel) {
    return this.additional.push(guest);
  }

  @action.bound clear = () => {
    this.main = new Guest();
    this.additional = [];
  };

  @action.bound remove(index: number) {
    if (this.additional.length > index) {
      this.additional.splice(index, 1);
    }
  }

  @action.bound update(index: number, guest: GuestModel) {
    if (index >= 0 && this.additional.length > index) {
      this.additional[index] = guest;
    }
  }

  @action.bound updateMainGuest(guest: GuestModel) {
    this.main = guest;
  }

  @action.bound updateMarketingOptin(value: boolean) {
    this.main = { ...this.main, isOptIn: value };
  }
}

import React, { useCallback, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Flex, Image, Input, Link, Spacer } from '@chakra-ui/react';
import Text from '@molecules/Text';
import Button from '@molecules/Button';
import Card from '@molecules/Card';
import BEIcon from '@molecules/BEIcon';
import { ROOT_ID } from '@view/constants/root';

import { API } from '../../config';

const localExampleProperties = [
  { id: '1', name: 'Property 1', url: '/reservation/Eki5mf' },
  { id: '2', name: 'Property 2', url: '/reservation/kdCUqm' },
];

const exampleProperties = {
  'https://hotels.acessa.loc': localExampleProperties,
  'https://hotels.cloudbeds-dev.com': [
    { id: '557016', name: 'up Delaware', url: '/reservation/jCvklW' },
    { id: '557027', name: 'Plastic Metal', url: '/reservation/ULiw1K' },
  ],
  'https://hotels.cloudbeds-local.com': localExampleProperties,
};

const Main: React.FC = () => {
  const [url, setURL] = useState<string>('');
  const navigate = useNavigate();
  const baseEndpoint = useMemo(() => {
    const loc = new URL(API);
    return loc.origin;
  }, []);

  const examples = useMemo(() => {
    const loc = new URL(API);
    const properties = exampleProperties[loc.origin] || [];
    return properties;
  }, []);

  const onClickOpenProperty = useCallback(() => {
    const loc = new URL(url);
    navigate(loc.pathname);
  }, [navigate, url]);

  return (
    <Flex id={ROOT_ID} justifyContent="center" p="20px" w="100%">
      <Flex
        alignItems="center"
        flexDir="column"
        gap="20px"
        maxW="640px"
        textAlign="center"
        w="100%"
      >
        <Image alt="logo" src="./cb-logo.svg" />
        <Card>
          <Text fontWeight={600} size="xl">
            BookingEngine v3
          </Text>
          <Flex gap="16px">
            <Input
              _placeholder={{ opacity: 0.6 }}
              bg="white"
              onChange={(e) => setURL(e.target.value)}
              placeholder="Booking Engine url..."
              value={url}
            ></Input>
            <Button onClick={onClickOpenProperty}>Open</Button>
          </Flex>
          <Flex gap="8px" mt="8px">
            <BEIcon color="neutral" name="info-circle" />
            <Text color="neutral" size="sm" textAlign="left">
              Enter the url of the Cloudbeds's booking engine you want to try on
              the new v3 system. It should be on the current environment{' '}
              <a href={baseEndpoint} rel="noreferrer" target="_blank">
                {baseEndpoint}
              </a>
            </Text>
          </Flex>
        </Card>

        {examples.length > 0 && (
          <Card>
            <Text fontWeight={600} size="xl">
              Example properties
            </Text>
            {examples.map((property) => (
              <Flex key={property.id}>
                <Link href={property.url}>
                  <Text>{property.name}</Text>
                </Link>
                <Spacer />
                <Link href={`${baseEndpoint}/connect/${property.id}`}>
                  <BEIcon name="magnify" />
                </Link>
                <Link href={`${baseEndpoint}${property.url}`}>
                  <BEIcon name="eye" />
                </Link>
              </Flex>
            ))}
          </Card>
        )}
      </Flex>
    </Flex>
  );
};

export default Main;

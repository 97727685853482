import { FeatureFlag, FeatureFlagManager } from '@business/feature-flags';

import { fixAPIEndpoint, getEnvFromUrlHost, pickRandom } from './helpers';
import { system } from './system';
import { EnvironmentType, EnvRecord } from './types';

const isLocalDevelopment = system.env === 'development';

const sourceIndex = Math.floor(Math.random() * 3) + 1;

const apiMap: EnvRecord = {
  dev: 'https://hotels.cloudbeds-dev.com/booking/',
  // @TECHDEBT: location.origin can't be used in the Immersive Experience
  devbox: `${location.origin}/booking/`,
  local: 'https://hotels.cloudbeds-local.com/booking/',
  prod: 'https://hotels.cloudbeds.com/booking/',
  stage: 'https://hotels.cloudbeds-stage.com/booking/',
};

const files: EnvRecord = {
  dev: `https://static${sourceIndex}.cloudbeds-dev.com/booking-engine/latest`,
  devbox: `https://static${sourceIndex}.cloudbeds-dev.com/booking-engine/latest`,
  // @TECHDEBT: location.origin can't be used in the Immersive Experience
  local: location.origin,
  prod: `https://static${sourceIndex}.cloudbeds.com/booking-engine/latest`,
  stage: `https://static${sourceIndex}.cloudbeds-stage.com/booking-engine/latest`,
};

const marketingInsightsService: EnvRecord = {
  dev: 'https://api.cloudbeds-dev.com/marketing-insights',
  devbox: 'https://api.cloudbeds-dev.com/marketing-insights',
  local: 'https://api.cloudbeds-dev.com/marketing-insights',
  prod: 'https://api.cloudbeds.com/marketing-insights',
  stage: 'https://api.cloudbeds-stage.com/marketing-insights',
};

const amplifyMetasearchConnectorService: EnvRecord = {
  dev: 'https://api.cloudbeds-dev.com/amplify-metasearch-connector',
  devbox: 'https://api.cloudbeds-dev.com/amplify-metasearch-connector',
  local: 'https://api.cloudbeds-dev.com/amplify-metasearch-connector',
  prod: 'https://api.cloudbeds.com/amplify-metasearch-connector',
  stage: 'https://api.cloudbeds-stage.com/amplify-metasearch-connector',
};

const launchdarklyKeyMap: EnvRecord = {
  dev: '6622ce31e5f8210fb2f449b1',
  devbox: '6622ce31e5f8210fb2f449b1',
  local: '6622ce31e5f8210fb2f449b1',
  prod: '6622cd9d6be9690f3ebae4e4',
  stage: '6622ce48f758a10f98ea6c92',
};

const ENVIRONMENT: EnvironmentType = (system.appEnv ||
  getEnvFromUrlHost(location.host)) as EnvironmentType;

function evaluateAPIEndpoint() {
  if (FeatureFlagManager.isEnabled(FeatureFlag.sendXPropertyCode)) {
    return system.apiBase || apiMap[ENVIRONMENT];
  }

  return (
    system.apiBase ||
    fixAPIEndpoint(
      apiMap[ENVIRONMENT],
      location.hostname,
      (document.currentScript as HTMLScriptElement)?.src
    )
  );
}

export const API = evaluateAPIEndpoint();

export const FILES =
  pickRandom(system.staticFiles) ||
  (isLocalDevelopment ? files.local : files[ENVIRONMENT]);

// @TECHDEBT: do we need this? how should we resolve it in the Immersive Experience
// manually set webpack public path
if (!isLocalDevelopment) {
  if (typeof window !== 'undefined') {
    window['__webpack_public_path__'] = FILES + '/';
  }
}

export const MARKETING_INSIGHTS_SERVICE_API =
  system.marketingInsightesServiceApiBase ||
  marketingInsightsService[ENVIRONMENT];

export const AMPLIFY_METASEARCH_CONNECTOR_SERVICE_API =
  system.amplifyMetasearchConnectorServiceApiBase ||
  amplifyMetasearchConnectorService[ENVIRONMENT];

export const LAUNCH_DARKLY_API_KEY =
  system.launchDarklyApiKey || launchdarklyKeyMap[ENVIRONMENT];

export const getApiFor = (env: EnvironmentType) => apiMap[env];

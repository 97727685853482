import { AmenityKeys } from '@molecules/Amenity/icons.types';
import { languages } from '@data/static/languages';
import { currencies } from '@data/static/currencies';

const languagesValues = languages.map((x) => x.value);
export type Language = (typeof languagesValues)[number];

export const isValidLanguage = (language: string): language is Language =>
  languagesValues.includes(language as Language);

const currenciesValues = currencies.map((x) => x.value);
export type Currency = (typeof currenciesValues)[number];

export const isValidCurrency = (currency: string): currency is Currency =>
  currenciesValues.includes(currency.toLowerCase() as Currency);

export type Amenity = {
  name: string;
  slug?: AmenityKeys;
};

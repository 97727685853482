import { PaymentMethodsType } from '@cloudbeds/payment-sdk';

import CreditCard from './CreditCard';

export type PAYMENT_METHOD = PaymentMethodsType;

type BillingDetails = {
  billingAddress: string;
  billingAddress2: string;
  billingCity: string;
  billingCountry: string;
  billingDocumentNumber: string;
  billingDocumentType: string;
  billingEmail: string;
  billingState: string;
  billingZip: string;
};

export type PaymentSubmitData = {
  billingDetails: BillingDetails;
  paymentMethod: PAYMENT_METHOD;
  paymentMethodHash: string;
  paymentMethodId: string;
  token: string;
};

export default class Payment {
  billingDetails?: PaymentSubmitData['billingDetails'];
  card?: CreditCard;
  hash?: PaymentSubmitData['paymentMethodHash'];
  id?: PaymentSubmitData['paymentMethodId'];
  method?: PaymentSubmitData['paymentMethod'];
  token?: PaymentSubmitData['token'];
}

export const isPaymentSubmitData = (data): data is PaymentSubmitData => {
  if (data && data['paymentMethodHash'] && data['paymentMethodId']) {
    return true;
  }
  return false;
};

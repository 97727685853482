import { useEffect, useMemo, useState } from 'react';

import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { getSize } from '@business/utils/object';
import { isAllotmentBlockCode } from '@business/utils/promocodes';
import TimeUtils from '@business/utils/time';
import { useStore } from '@data/other/withStore';
import { Language, isValidCurrency } from '@data/types/common';
import { Routes } from '@view/routes';
import { getAllSearchParams } from '@view/utils/search-params';

import { useApplicationFlow } from './useApplicationFlow';
import { useLegacyUrlParams } from './useLegacyUrlParams';

export const useURLCorrection = () => {
  const [isUrlVerified, setIsUrlVerified] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams({});

  const params = useLegacyUrlParams();
  const navigate = useNavigate();

  const { pathname, search } = useLocation();
  const { propertyCode } = useParams();
  const { currentPage } = useApplicationFlow();

  const {
    components: { searchPanel },
    property: { reservation },
  } = useStore();

  const currentParams = useMemo(
    () => getAllSearchParams(searchParams),
    [searchParams]
  );

  // Observing changes in search parameters to update the store
  useEffect(() => {
    // Ignore this effect if URL hasn't been verified yet (to prevent double calls on the initial load)
    if (!isUrlVerified) {
      return;
    }

    // Update store when going back and forward in the Routes.Accommodations page
    if (currentPage !== Routes.Accommodations) {
      return;
    }

    const isNavigatingToLandingPage =
      !currentParams.checkin &&
      !currentParams.checkout &&
      !currentParams.promo &&
      !currentParams.allotment_block_code;

    // Needed for going back and forward in the browser's history
    if (isNavigatingToLandingPage) {
      return searchPanel.clear();
    }

    const isNavigatingFromLandingPage =
      !searchPanel.value.checkin &&
      !searchPanel.value.checkout &&
      !searchPanel.value.code;

    // Needed for going back and forward in the browser's history
    if (isNavigatingFromLandingPage) {
      return searchPanel.set({
        adults: currentParams.adults ? Number(currentParams.adults) : undefined,
        checkin: TimeUtils.fromIso(currentParams.checkin, { startOfDay: true }),
        checkout: TimeUtils.fromIso(currentParams.checkout, {
          startOfDay: true,
        }),
        code: currentParams.promo || currentParams.allotment_block_code,
        kids: currentParams.adults ? Number(currentParams.kids) : undefined,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentParams.allotment_block_code,
    currentParams.checkin,
    currentParams.checkout,
    // currentParams.data_res,
    currentParams.promo,
    // currentPage is not included in the dependency array as we don't want this effect to run when the page changes, only when the search params change
    searchPanel,
  ]);

  const correctSearchParams = () => {
    const currentParams = getAllSearchParams(searchParams);

    const newParams: Record<string, string> = {};

    if (
      params.checkin &&
      TimeUtils.format(params.checkin) !== currentParams.checkin
    ) {
      newParams.checkin = TimeUtils.format(params.checkin);
    }

    if (TimeUtils.format(params.checkout) !== currentParams.checkout) {
      if (!params.checkout && !!currentParams.checkout) {
        delete currentParams.checkout;
      }

      if (!!params.checkout || !!currentParams.checkout) {
        newParams.checkout =
          (params.checkout && TimeUtils.format(params.checkout)) ||
          currentParams.checkout;
      }
    }

    if (params.ratePlans !== currentParams.rate_plan) {
      newParams.rate_plan = params.ratePlans;
    }

    if (params.roomType !== currentParams.room_type) {
      newParams.room_type = params.roomType;
    }

    if (params.adults !== currentParams.adults) {
      newParams.adults = params.adults;
    }

    if (params.kids !== currentParams.kids) {
      newParams.kids = params.kids;
    }

    if (params.roomIds !== currentParams.rid) {
      newParams.rid = params.roomIds;
    }

    if (params.code) {
      if (isAllotmentBlockCode(params.code)) {
        if (params.code !== currentParams.allotment_block_code) {
          newParams.allotment_block_code = params.code;
        }
      } else {
        if (params.code !== currentParams.promo) {
          newParams.promo = params.code;
        }
      }
    }

    if (params.utm_source !== currentParams.utm_source) {
      newParams.utm_source = params.utm_source;
    }

    if (params.currency || currentParams.currency) {
      const currency = params.currency || currentParams.currency;
      if (isValidCurrency(currency)) {
        newParams.currency = currency.toLowerCase();
      } else {
        delete params.currency;
        delete currentParams.currency;
      }
    }

    const shouldUpdateSearchParams =
      getSize(newParams) > 0 || getSize(currentParams) !== searchParams.size;

    if (shouldUpdateSearchParams) {
      setSearchParams({ ...currentParams, ...newParams }, { replace: true });
    }
  };

  const correctConfirmationURL = async () => {
    const dataRes = searchParams.get('data_res');

    if (dataRes) {
      const reservationModel = await reservation.get(dataRes);

      if (reservationModel?.propertyCode) {
        const lang = reservationModel?.lang as Language;

        navigate(
          `/${lang}/reservation/${reservationModel?.propertyCode}/confirmation${search}`,
          { replace: true }
        );
      }
    }

    setIsUrlVerified(true);
  };

  const correctURL = async () => {
    if (!propertyCode && pathname.endsWith(`/confirmation`)) {
      return await correctConfirmationURL();
    }

    correctSearchParams();

    setIsUrlVerified(true);
  };

  return { correctURL, isUrlVerified };
};

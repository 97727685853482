import { RawReservationResponse } from '@data/api/types/reservation-response';

export default class ReservationResponse {
  customer_id!: number;
  enc_res_id!: string;
  error?: string;
  redirect_uri?: {
    method: 'GET' | 'POST';
    postData?: Record<string, string>;
    url: string;
  };
  reservation_id!: string;
  status!: boolean;

  constructor(
    data?: RawReservationResponse & {
      message?: string;
      success: boolean;
    }
  ) {
    if (!data || !data.success) {
      this.status = false;
      this.error = data?.message;

      return;
    }

    this.status = true;
    this.reservation_id = data.reservation_id;
    this.enc_res_id = data.enc_res_id;
    this.customer_id = parseInt(data.customer_id);
    if (data.redirect_uri) {
      const { postData } = data.redirect_uri;
      this.redirect_uri = {
        method: data.redirect_uri.method,
        postData: Array.isArray(postData) ? undefined : postData,
        url: data.redirect_uri.url,
      };
    }
  }
}

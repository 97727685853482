import { action, computed, makeObservable, observable } from 'mobx';

import { Payment as PaymentModel } from '@data/models';
import { PAYMENT_METHOD, PaymentSubmitData } from '@data/models/Payment';

export default class Payment {
  @observable model: PaymentModel = new PaymentModel();

  constructor() {
    makeObservable(this);
  }

  @action.bound clear = () => {
    this.model.billingDetails = undefined;
    this.model.card = undefined;
    this.model.hash = undefined;
    this.model.id = undefined;
    this.model.method = undefined;
    this.model.token = undefined;
  };

  @action.bound setCreditCard = (card: PaymentModel['card']) => {
    this.model.card = card;
  };

  @action.bound setMethod = (method: PAYMENT_METHOD) => {
    this.model.method = method;
  };

  @action.bound setSubmitData = (data: PaymentSubmitData) => {
    this.model.method = data.paymentMethod;
    this.model.hash = data.paymentMethodHash;
    this.model.billingDetails = data.billingDetails;
    this.model.id = data.paymentMethodId;
    this.model.token = data.token;
  };

  @computed get creditCard() {
    return this.model.card;
  }
}

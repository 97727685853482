import { EnvironmentType } from './types';

class System {
  amplifyMetasearchConnectorServiceApiBase?: string;
  apiBase?: string;
  appEnv?: EnvironmentType;
  env?: 'development' | 'test' | 'production';
  launchDarklyApiKey?: string;
  marketingInsightesServiceApiBase?: string;
  staticFiles?: string;

  static create = () => new System();

  load = (settings: Partial<System>) =>
    Object.keys(settings).forEach((key) => {
      this[key] = settings[key];
    });
}

export const system = System.create();

import { Spinner as Spin } from '@chakra-ui/react';
import styled from '@emotion/styled';

export interface LoaderProps {
  className?: string;
  fixed?: boolean;
  large?: boolean;
  testid?: string;
}

const Loader = ({ className, testid }: LoaderProps) => (
  <Spin className={className} data-testid={testid || 'loader'} />
);

export default styled(Loader)`
  ${({ fixed }) =>
    fixed && {
      height: '32px',
      left: '50%',
      position: 'fixed',
      top: '50%',
      transform: 'translate(-50%, 0)',
      width: '32px',
    }}
`;

import { action, makeObservable, observable } from 'mobx';

export type Value = {
  adults?: number;
  checkin?: Date;
  checkout?: Date;
  code?: string;
  kids?: number;
  ratePlans?: string;
  roomIds?: string;
  roomType?: string;
};

export default class SearchPanel {
  @observable stagedValue: Value = {};
  @observable value: Value = {};

  constructor() {
    makeObservable(this);
  }

  @action.bound clear() {
    this.value = {};
  }

  @action.bound clearStagedValue() {
    this.stagedValue = {};
  }

  @action.bound initializeComponent(value: Value) {
    this.value = value;
    this.stagedValue = value;
  }

  @action.bound set(value: Value) {
    this.value = { ...this.value, ...value };
    this.stagedValue = { ...this.stagedValue, ...value };
  }

  @action.bound setStagedValue(value: Value) {
    this.stagedValue = { ...this.stagedValue, ...value };
  }
}

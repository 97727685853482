import React, { Suspense, useEffect } from 'react';

import { Route, Routes } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';

import MainPage from '@view/pages/Main';
import Loader from '@view/components/Loader';
import { useURLCorrection } from '@view/hooks/useURLCorrection';

import { Routes as AppRoutes } from '../routes';

const NotFoundPage = React.lazy(
  () => import(/* webpackChunkName: "not-found" */ '@view/pages/NotFound')
);

const Property = React.lazy(
  () => import(/* webpackChunkName: "property" */ '@view/pages/Property')
);

const createDevRoutes =
  [
    'localhost',
    '127.0.0.1',
    'booking-engine.cloudbeds.com',
    'booking-engine.cloudbeds-dev.com',
    'booking-engine.cloudbeds-stage.com',
  ].indexOf(window.location.hostname) > -1;

const Root = () => {
  const { correctURL, isUrlVerified } = useURLCorrection();

  useEffectOnce(() => {
    correctURL();
  });

  useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      if (event.detail > 1) {
        event.preventDefault();
      }
    };

    /* preventing select text on double click */
    document.addEventListener('mousedown', handleMouseDown, false);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  // Prevent app from rendering if URL is not verified yet
  if (!isUrlVerified) {
    return null;
  }

  return (
    <Suspense fallback={<Loader fixed testid="root-loader" />}>
      <Routes>
        {createDevRoutes && <Route element={<MainPage />} path="/" />}
        <Route element={<NotFoundPage />} path="/404" />
        <Route element={<Property />} path={`${AppRoutes.Property}/*`} />
        <Route element={<Property />} path={`${AppRoutes.PropertyBr}/*`} />
        <Route element={<Property />} path={`/:lang${AppRoutes.Property}/*`} />
        <Route
          element={<Property />}
          path={`/:lang${AppRoutes.PropertyBr}/*`}
        />
        <Route element={<NotFoundPage />} path="*" />
      </Routes>
    </Suspense>
  );
};

export default Root;

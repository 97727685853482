import { makeObservable, observable, runInAction } from 'mobx';

import { ReservationService } from '@data/api';
import { Reservation as ReservationModel } from '@data/models';
import { MakeReservationRequest } from '@data/api/services/reservation';
import { eventSystem } from '@business/event-system';
import { PAYMENT_METHOD } from '@data/models/Payment';

const storage = window.sessionStorage;

export default class Reservation {
  @observable model: ReservationModel | null = null;
  @observable reservationCode: string | null = null;

  constructor() {
    makeObservable(this);
  }

  clearCreatedReservationId() {
    storage.removeItem('createdReservationId');
  }

  async get(reservationCode: string): Promise<ReservationModel | null> {
    const result = await ReservationService.getReservation(reservationCode);
    runInAction(() => {
      this.reservationCode = decodeURIComponent(reservationCode);
      this.model = result;
    });

    return this.model;
  }

  isReservationJustCreated() {
    const storedValue = storage.getItem('createdReservationId');
    return this.model && storedValue === this.model.reservationId;
  }

  async make(req: MakeReservationRequest) {
    this.clearCreatedReservationId();
    runInAction(() => {
      this.model = null;
      this.reservationCode = null;
    });

    const makeResult = await ReservationService.makeReservation(req);
    if (makeResult.status) {
      eventSystem.emit('internal/checkout-initiated', {
        paymentMethod: req.payment.method as PAYMENT_METHOD,
      });
    }

    if (makeResult?.status) {
      storage.setItem('createdReservationId', makeResult.reservation_id);
      runInAction(() => {
        this.reservationCode = makeResult.enc_res_id;
      });
    } else {
      throw new Error('payment processing error');
    }
    return makeResult;
  }
}

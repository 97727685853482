import MetaPixel from 'react-facebook-pixel';

import { AccommodationList } from '@data/models';
import { Currency, Language } from '@data/types/common';
import Store from '@data/stores/Store';

import { apiCall, IRequestOptions } from '../axios';

interface AccommodationSearchParams {
  adults?: number;
  allotment_block_code?: string;
  checkin: string;
  checkout: string;
  currency_code: Currency;
  kids?: number;
  lang: Language;
  promo_code?: string;
  rate_plan?: string;
  room_ids?: string;
  room_type?: string;
  widget_property: number;
}

export class AccommodationsService {
  static search(
    params: AccommodationSearchParams,
    options: IRequestOptions = {}
  ) {
    Store.environment.setCurrencyRate(0);
    return new Promise<AccommodationList>((resolve, reject) => {
      MetaPixel.track('Search');
      const url = '/rooms';
      apiCall(AccommodationList)(
        'post',
        url,
        {
          ...params,
          currency_code: params.currency_code.toUpperCase(),
        },
        options
      )
        .then((res) => {
          const accommodationList = res;
          accommodationList.checkin = params.checkin;
          accommodationList.checkout = params.checkout;
          Store.environment.setCurrencyRate(
            accommodationList.currency_rate || 1
          );
          resolve(accommodationList);
        })
        .catch(reject);
    });
  }
}
